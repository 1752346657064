<template>
  <div class="home">
    <div class="content">
      <p>{{ $t("aboutMissiontitle") }}</p>
    </div>
    <footer class="row-b animated fadeInUp">
      <div class="left row-b"></div>
      <!-- <div class="right col-b_">
        <div class="up row-b">
          <a href="">Security Report</a>
          <a href=""> Privacy and Cookies </a>
          <a href="">Cookie Settings</a>
        </div>
        <div class="down row-e">© 2020 Web3 Foundation, All Rights Reserved.</div>
      </div> -->
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mobile: false, // 当前是否移动端
      show: true, // 是否显示列表
      btn: require("../assets/img/list.png"),
      // slogan: 'We invest research and development teams who are building the foundation of the decentralized web.'
    };
  },
  created() {
    if (document.documentElement.clientWidth < 940) {
      this.mobile = true;
      this.show = false;
    }
  },
  computed: {
    width() {
      return document.documentElement.clientWidth;
    },
  },
  watch: {
    width(n, o) {
      if (n < 940) {
        this.mobile = true;
        this.show = false;
      }
    },
    show(n, o) {
      n
        ? (this.btn = require("../assets/img/cld.png"))
        : (this.btn = require("../assets/img/list.png"));
    },
  },
  methods: {
    change(e) {
      if (this.mobile) {
        this.show = !this.show;
      }
    },
  },
};
</script>
<style lang="less" scoped>
// pc

.home {
  width: 100%;
  height: 100%;
  background: url("../assets/img/homeBg.jpg") no-repeat;
  background-size: cover;
  overflow: hidden;
  position: absolute;
  left: 0;
  padding: 21vh 0 0 3vw;
  box-sizing: border-box;
  > #nav {
    width: 100%;
    height: 12vh;
    position: relative;
    top: 0;
    padding: 1.5vh 0;
    box-sizing: border-box;
    z-index: 99;
    > .content {
      width: 75vw;
      height: 100%;
      > .left {
        max-width: 9vw;
        // height: 100%;
        // background: #ff0;

        transform: scale(0.5);
        > img {
          width: 100%;
          height: 100%;
        }
      }
      > .right {
        width: 36vw;
        height: 100%;
        font-size: 1.1vw;
        // background: #0f0;
        > .all {
          width: 100%;
        }
        .item {
          color: #fff;
          height: 100%;
          cursor: pointer;
          // background: #0ff;
          position: relative;
          font-size: 1.3vw;
        }
        .item::after {
          content: "";
          display: block;
          width: 0%;
          height: 4px;
          background: #fff;
          position: absolute;
          left: 0;
          top: 120%;
          transition: all ease 0.3s;
        }
        .item:hover::after {
          width: 100%;
        }
        > .now::after {
          width: 100%;
        }
      }
    }
  }
  > .content {
    width: 75vw;
    margin: 0 auto;
    margin-top: 20px;
    > p {
      width: 60%;
      color: #fff;
      font-size: 2vw;
      font-weight: 900;
    }
  }
  > footer {
    width: 75vw;
    margin: 0 auto;
    // background: #ff0;
    position: absolute;
    bottom: 8vh;
    left: 50%;
    margin-left: -37.5vw;
    > .left {
      width: 20vw;
      height: 10vh;
      background: url("../assets/img/dlogo.png") no-repeat;
      background-size: contain;
    }
    > .right {
      width: 20vw;
      // background: #666;
      font-size: 14px;
      > .up {
        width: 100%;
      }
      color: #7f7f7f;
      div {
        width: 100%;
      }
      a {
        color: #7f7f7f;
        font-size: 14px;
      }
      a:hover {
        color: #eee;
      }
    }
  }
}

@media screen and (max-width: 940px) {
  // 移动设备
  html,
  :root,
  body {
    font-size: 10px;
  }
  .home > .content {
    width: 100%;
    > p {
      width: 100%;
      font-size: 5.8vw;
    }
  }

  .home > footer {
    // background: #0ff ;
    margin-left: -45vw;
    > .left {
      width: 80%;
      > .item {
        width: 30%;
      }
    }
    > .right {
      width: 100%;
      > .up {
        width: 80%;
      }
    }
  }
}
</style>
